import LogReport from "../components/Comingsoon";
import SlogWeld from "../components/LogData/SlogWeld";
import HotPassTable from "../components/PqrReportData/HotPassTable";
import PqrReportTable from "../components/subcomponents/PqrReportTable";
import TlogData from "../components/subcomponents/TlogData";
import { HomeLayout } from "../layout";
import Home from "../pages/home-page";

export default [
  {
    path: "/",
    exact: true,
    layout: HomeLayout,
    component: Home,
  },
  {
    path: "/data360-home",
    layout: HomeLayout,
    component: Home,
  },
  {
    path: "/data360-home/pqr-report",
    layout: HomeLayout,
    component: Home,
  },
  {
    path: "/log-report",
    layout: HomeLayout,
    component: LogReport,
  },

  {
    path: "/slog-weld/:weld_number",
    layout: HomeLayout,
    component: SlogWeld,
  },
  {
    path: "/tlog-data",
    layout: HomeLayout,
    component: TlogData,
  },
  {
    path: "/tlog-data/:passName",
    layout: HomeLayout,
    component: TlogData,
  },
  {
    path: "/pqr-table-data/:weld_numbers",
    layout: HomeLayout,
    component: PqrReportTable,
  },
  {
    path: "/wps-limit-table",
    layout: HomeLayout,
    component: HotPassTable,
  },
];
