import {
  CloseOutlined,
  DownCircleOutlined,
  ReloadOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import { Col, Button, Tooltip, notification } from "antd";
import moment from "moment";

import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-elastic-carousel";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import SearchModal from "../components/Search";
import { commonConstants } from "../constants";
import { projectListingHelper } from "../helpers";
import {
  TOGGLE_PROJECT_DETAILS,
  TOGGLE_PROJECT_LIST,
} from "../redux/application/types";
import { SET_SELECTED_PROJECT } from "../redux/project/types";
import "../global-styles/antd-custom.scss";

const ProjectListHeader = (props) => {
  const { reloadData, projectListData, setProjectList, handlerClearSearch } =
    props;
  const { t } = useTranslation();
  const reducerList = useSelector((state) => state);
  const dispatch = useDispatch();
  const carouselRef = useRef(null);
  const [searchText, onSearch] = useState("");
  const [searchNotFound, setSearchNotFound] = useState(false);
  const [showCarousel, setShowCarousel] = useState(false);
  const [showProjects, setShowProjects] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getCarouselConfig = (width) => {
    if (width < 1280) return { itemsToShow: 4, itemsToScroll: 1 };
    if (width < 1440) return { itemsToShow: 6, itemsToScroll: 1 };
    if (width < 1600) return { itemsToShow: 7, itemsToScroll: 1 };
    if (width < 1920) return { itemsToShow: 8, itemsToScroll: 1 };
    if (width < 2400) return { itemsToShow: 11, itemsToScroll: 1 };
    return { itemsToShow: 12, itemsToScroll: 1 };
  };

  const handleTitleClick = () => {
    setShowCarousel(!showCarousel);
    dispatch({ type: TOGGLE_PROJECT_DETAILS, payload: !showCarousel });
  };

  const handleProjectClick = () => {
    setShowProjects((showProjects) => !showProjects);
    dispatch({ type: TOGGLE_PROJECT_LIST, payload: !showProjects });
  };

  const getSelectedProject = (project_id, project_name) => {
    const project = projectListData?.find(
      (item) =>
        item.project_id === project_id && item.project_name === project_name
    );

    if (project) {
      const { itemsToShow } = getCarouselConfig(windowWidth);
      const reversedList = projectListData.slice().reverse();

      const selectedIndex = reversedList.findIndex(
        (p) => p.project_id === project_id && p.project_name === project_name
      );

      if (selectedIndex !== -1) {
        const totalItems = reversedList.length;
        let targetIndex = selectedIndex;

        targetIndex = Math.min(targetIndex, totalItems - itemsToShow);

        dispatch({
          type: SET_SELECTED_PROJECT,
          payload: project,
        });

        if (carouselRef.current) {
          carouselRef.current.goTo(targetIndex);
        }
      }
    }
  };

  useEffect(() => {
    const selectedProject = reducerList?.projectReducer?.selectedProject;
    if (selectedProject) {
      getSelectedProject(
        selectedProject.project_id,
        selectedProject.project_name
      );
    }
  }, [
    projectListData,
    reducerList?.projectReducer?.selectedProject?.project_id,
    reducerList?.projectReducer?.selectedProject?.project_name,
    dispatch,
    windowWidth,
    projectListData,
  ]);

  const carouselConfig = [
    { width: 1280, ...getCarouselConfig(1280) },
    { width: 1440, ...getCarouselConfig(1440) },
    { width: 1600, ...getCarouselConfig(1600) },
    { width: 1920, ...getCarouselConfig(1920) },
    { width: 2400, ...getCarouselConfig(2400) },
  ];

  const onCancel = () => {
    setSearchNotFound("");
  };

  const handleSearch = (searchTerm) => {
    onSearch(searchTerm);
  };

  const handleClickSearch = ({ callBack }) => {
    if (!searchText) {
      notification.warn({
        message: t("projectDetails.enterProjectName"),
      });

      return;
    }

    const filteredProjects = reducerList.projectReducer.projectList?.filter(
      (project) =>
        project.project_name.toLowerCase().includes(searchText) ||
        project.project_number.toLowerCase().includes(searchText)
    );

    if (filteredProjects.length > 0) {
      setProjectList(filteredProjects);

      dispatch({
        type: SET_SELECTED_PROJECT,
        payload: {},
      });

      if (carouselRef.current) {
        carouselRef.current.goTo(0);
      }

      callBack();
    } else {
      notification.error({
        message: t("projectDetails.noProject") + searchText,
      });
    }
  };

  const weldingSystems = (welding_system) => {
    let weldSystem = "";

    try {
      let weldingSystemObj = JSON.parse(welding_system || "{}");

      const weldingSystems = Object.keys(weldingSystemObj).filter(
        (key) => weldingSystemObj[key]
      );

      weldSystem =
        weldingSystems.length > 0 ? `${weldingSystems.join(", ")} ` : "-";
    } catch (error) {
      weldSystem = welding_system;
    }

    return weldSystem;
  };

  const getCarouselClassName = (projectListLength) => {
    return projectListLength <= 5 ? "fourflexslider h-100" : "flexslider h-100";
  };

  return (
    <>
      <div className="project-carousel">
        <div className="project-list" onClick={handleProjectClick}>
          {!showProjects ? (
            <DownCircleOutlined className="downoutlined-icon-projectList" />
          ) : (
            <UpCircleOutlined className="upoutlined" />
          )}{" "}
          <h1 className="project-title ">{t("common.projects")}</h1>
          <h6 className="mx-3 mt-1">
            {!showProjects &&
            Object.keys(reducerList?.projectReducer?.selectedProject).length ? (
              <b>{`${reducerList?.projectReducer?.selectedProject?.project_number}\t-\t${reducerList?.projectReducer?.selectedProject?.project_name}`}</b>
            ) : (
              ""
            )}
          </h6>
        </div>
        <div className="search-container d-flex">
          {searchText !== "" && searchNotFound === false && (
            <div className="top-bar-input d-flex justify-content-between align-items-center me-3">
              <span>{searchText}</span>{" "}
              <a
                className="export-link"
                onClick={() => {
                  onSearch("");
                  handlerClearSearch();
                }}
              >
                <CloseOutlined />
              </a>{" "}
            </div>
          )}
          <SearchModal
            onSearch={(e) => handleSearch(e.target.value.toLowerCase())}
            searchText={searchText}
            setValue={(val) => onSearch(val)}
            onCancel={onCancel}
            handleClickSearch={handleClickSearch}
            projectNumbers={projectListData}
          />

          <Tooltip placement="topLeft" title="Reload">
            <Button
              className="refresh ms-3"
              shape="circle"
              onClick={() => {
                reloadData();
              }}
            >
              <ReloadOutlined />
            </Button>
          </Tooltip>
        </div>
      </div>
      <div>
        <div
          className={
            showProjects
              ? "project-list-carousel"
              : "project-list-carousel-hidden"
          }
        >
          {projectListData?.length > 0 && (
            <div className="flex-fill overflow-auto">
              <div className={getCarouselClassName(projectListData.length)}>
                <Carousel
                  ref={carouselRef}
                  pagination={false}
                  breakPoints={carouselConfig}
                >
                  {projectListData
                    .slice()
                    .reverse()
                    .map((data, index) => {
                      return (
                        <div
                          className="float-start slides-divs slides"
                          key={`${data.project_id}-${index}`}
                        >
                          <a
                            id={data.project_id}
                            onClick={() => {
                              getSelectedProject(
                                data.project_id,
                                data.project_name
                              );
                            }}
                            className={
                              data.project_id ===
                                reducerList?.projectReducer?.selectedProject
                                  ?.project_id &&
                              data.project_name ===
                                reducerList?.projectReducer?.selectedProject
                                  ?.project_name
                                ? "slides-items active"
                                : "slides-items"
                            }
                          >
                            <div className="text-center tooltip-parent">
                              {" "}
                              <span
                                className={
                                  data.project_id ===
                                    reducerList?.projectReducer?.selectedProject
                                      ?.project_id &&
                                  data.project_name ===
                                    reducerList?.projectReducer?.selectedProject
                                      ?.project_name
                                    ? "tooltip-text-active"
                                    : "tooltip-text"
                                }
                              >
                                {data.project_name.length > 20
                                  ? data.project_name
                                      .substring(0, 30)
                                      .concat(
                                        `\n ${data.project_name.substring(
                                          30,
                                          data.project_name.length
                                        )}`
                                      )
                                  : ""}
                              </span>
                              <span>
                                {data.project_name.length > 20
                                  ? data.project_name
                                      .substring(0, 19)
                                      .concat("...")
                                  : data.project_name}
                              </span>
                            </div>
                            <div className="text-center tooltip-parent">
                              {" "}
                              <span>
                                {data.project_number.length > 20
                                  ? data.project_number
                                      .substring(0, 19)
                                      .concat("...")
                                  : data.project_number}
                              </span>
                              <span
                                className={
                                  data.project_id ===
                                    reducerList?.projectReducer?.selectedProject
                                      ?.project_id &&
                                  data.project_name ===
                                    reducerList?.projectReducer?.selectedProject
                                      ?.project_name
                                    ? "tooltip-text-active-bottom"
                                    : "tooltip-text-bottom"
                                }
                              >
                                {data.project_number.length > 20
                                  ? data.project_number
                                      .substring(0, 30)
                                      .concat(
                                        `\n ${data.project_number.substring(
                                          30,
                                          data.project_number.length
                                        )}`
                                      )
                                  : ""}
                              </span>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                </Carousel>
              </div>
            </div>
          )}
        </div>
        <div className="custom-line mb-3" />
      </div>

      {reducerList?.projectReducer?.selectedProject?.project_id &&
      !showCarousel ? (
        <div className="project-details-hide" onClick={handleTitleClick}>
          <DownCircleOutlined className="downoutlined-icon" />
          <h6 className="p-details-title">
            {t("projectDetails.projectDetail")}
          </h6>
          <div className="custom-line mb-3 p-d" />
        </div>
      ) : (
        <div>
          <div className="p-d-f">
            {reducerList?.projectReducer?.selectedProject?.project_id && (
              <UpCircleOutlined
                onClick={handleTitleClick}
                className="upoutlined"
              />
            )}
          </div>
          <div className="project-detail-bar">
            <div className="project-detail-bar-items h-100 mb-3">
              {reducerList?.projectReducer?.selectedProject?.project_id && (
                <>
                  <div className="flex-fill overflow-auto">
                    <div className="flexslider-bt flexslider-small h-100">
                      <Carousel
                        pagination={false}
                        breakPoints={commonConstants.carouselConfig}
                      >
                        {projectListingHelper.displayFields?.map((project) => {
                          if (project.value === "welding_system") {
                            return (
                              <Col key={project.value} span={24}>
                                <div className="project-details-wrapper text-center">
                                  <p className="fw-medium title">
                                    {t(`${project.name}`)}
                                  </p>
                                  <div className="d-flex edit-session justify-content-center mb-2">
                                    <p className="mb-0">
                                      {weldingSystems(
                                        reducerList?.projectReducer
                                          ?.selectedProject?.welding_system
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            );
                          } else if (
                            project.value === "welding_assignment_type"
                          ) {
                            let weldAssignmentType =
                              reducerList?.projectReducer?.selectedProject
                                ?.weldAssignmentType;
                            console.log({ weldAssignmentType });
                            let assignedWeldType = "Manual";
                            if (weldAssignmentType) {
                              if (weldAssignmentType === "BAR") {
                                assignedWeldType = "Manual";
                              } else {
                                assignedWeldType = "Automatic";
                              }
                            }
                            return (
                              <Col key={project.value} span={24}>
                                <div className="project-details-wrapper text-center">
                                  <p className="fw-medium title">
                                    {t(`${project.name}`)}
                                  </p>
                                  <div className="d-flex edit-session justify-content-center mb-2">
                                    <p className="mb-0">{assignedWeldType}</p>
                                  </div>
                                </div>
                              </Col>
                            );
                          } else if (
                            project.value === "start_date" ||
                            project.value === "end_date"
                          ) {
                            const dateValue =
                              reducerList?.projectReducer?.selectedProject[
                                project.value
                              ];
                            const formattedDate = dateValue
                              ? moment(new Date(dateValue)).format(
                                  "DD-MMM-YYYY"
                                )
                              : "-";
                            return (
                              <Col key={project.value} span={24}>
                                <div className="project-details-wrapper text-center">
                                  <p className="fw-medium title">
                                    {t(`${project.name}`)}
                                  </p>
                                  <div className="d-flex edit-session justify-content-center mb-2">
                                    <p className="mb-0"> {formattedDate}</p>
                                  </div>
                                </div>
                              </Col>
                            );
                          } else if (project.value === "welds_completed") {
                            const completed =
                              reducerList?.projectReducer?.selectedProject
                                ?.welds_completed || 0;
                            const planned =
                              reducerList?.projectReducer?.selectedProject
                                ?.welds_planned || 0;
                            return (
                              <Col key={project.value} span={24}>
                                <div className="project-details-wrapper text-center">
                                  <p className="fw-medium title">
                                    {t(`${project.name}`)}
                                  </p>
                                  <div className="d-flex edit-session justify-content-center mb-2">
                                    <p className="mb-0">{`${completed} of ${planned}`}</p>
                                  </div>
                                </div>
                              </Col>
                            );
                          } else if (project.value === "iwm") {
                            const iwmValue =
                              reducerList?.projectReducer?.selectedProject[
                                project.value
                              ];
                            return (
                              <Col key={project.value} span={24}>
                                <div className="project-details-wrapper text-center">
                                  <p className="fw-medium title">
                                    {t(`${project.name}`)}
                                  </p>
                                  <div className="d-flex edit-session justify-content-center mb-2">
                                    <p className="mb-0">
                                      {iwmValue
                                        ? t("common.yes")
                                        : t("common.no")}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            );
                          } else if (project.value === "num_stations") {
                            const numstations =
                              reducerList?.projectReducer?.selectedProject[
                                project.value
                              ];
                            return (
                              <Col key={project.value} span={24}>
                                <div className="project-details-wrapper text-center">
                                  <p className="fw-medium title">
                                    {t(`${project.name}`)}
                                  </p>
                                  <div className="d-flex edit-session justify-content-center mb-2">
                                    <p className="mb-0">
                                      {Math.floor(numstations)}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            );
                          } else {
                            const fieldValue =
                              reducerList?.projectReducer?.selectedProject[
                                project.value
                              ] || "-";
                            return (
                              <Col key={project.value} span={24}>
                                <div className="project-details-wrapper text-center">
                                  <p className="fw-medium title">
                                    {t(`${project.name}`)}
                                  </p>
                                  <div className="d-flex edit-session justify-content-center mb-4">
                                    <div className="text-center tooltip-display-parent">
                                      <p className="mb-0">
                                        {" "}
                                        {fieldValue.length > 19
                                          ? fieldValue
                                              .substring(0, 15)
                                              .concat("...")
                                          : fieldValue}
                                      </p>
                                      <span className="tooltip-display-text">
                                        {fieldValue.length > 15
                                          ? fieldValue
                                              .substring(0, 30)
                                              .concat(
                                                `\n ${fieldValue.substring(
                                                  30,
                                                  fieldValue.length
                                                )}`
                                              )
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            );
                          }
                        })}
                      </Carousel>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectListHeader;
