import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ExclamationIcon from "../../assets/exclamation.svg";
import { commonConstants } from "../../constants";
import { logDataHelperFunctions } from "../../helpers/logdata-helper";
import { passViewHelper } from "../../helpers/passzone/pass-zone-helper";
import CommonLoader from "../Loaders/loader";

const PassZone = ({ visibleColumns, weld_number }) => {
  const { t } = useTranslation();
  const { zoneviewReducer, projectReducer } = useSelector((state) => state);
  const [pageSize, setPageSize] = useState(commonConstants.defaultPageSize);

  const [weldParamsShowColumns, setWeldParamsShowColumns] = useState([]);

  const [reportColumns, setReportColumns] = useState(
    logDataHelperFunctions.sampleReportColumns
  );

  useEffect(() => {
    if (projectReducer?.selectedProject?.reportColumns) {
      try {
        let parsedData = JSON.parse(
          projectReducer?.selectedProject?.reportColumns
        );
        setReportColumns(parsedData);
      } catch (e) {
        console.log(e);
      }
    }
  }, [projectReducer?.selectedProject?.reportColumns]);

  useEffect(() => {
    if (zoneviewReducer?.zoneviewList?.weldParams?.length > 0) {
      let allMappedValues = zoneviewReducer?.zoneviewList?.weldParams
        ?.map((ite) => {
          try {
            let params = ite.parameter_check_list || {};
            let enabledKeys = [];
            for (let key of Object.keys(params)) {
              if (params[key]) {
                enabledKeys.push(key);
              }
            }

            return {
              job_number: ite.job_number,
              enabledKeys:
                enabledKeys?.length > 0
                  ? enabledKeys
                  : logDataHelperFunctions.defaultParamsValues,
            };
          } catch (e) {
            console.log(e);
            return null;
          }
        })
        .filter((ite) => ite != null);

      setWeldParamsShowColumns(allMappedValues);
    }
  }, [zoneviewReducer?.zoneviewList?.weldParams]);

  const avgTlogList =
    zoneviewReducer?.zoneviewList?.avgTlogList?.recordList || [];
  const loader = zoneviewReducer?.isZoneviewLoding;
  const slogData = zoneviewReducer?.zoneviewList?.allSlogs?.recordList || [];
  const tableLimitData =
    zoneviewReducer?.zoneviewList?.tableLimitData?.recordList || [];
  const history = useHistory();

  // Extract unique device IDs from slog data
  const deviceid_events = [
    ...new Set(slogData.map((item) => item.deviceid_event)),
  ];

  // Function to aggregate values
  const aggregateValues = (data, station_number) => {
    const highLowMedData = data.reduce((acc, tlogList) => {
      for (const tlogItemKey in tlogList) {
        if (
          tlogItemKey.endsWith("_avg") ||
          tlogItemKey.endsWith("_high") ||
          tlogItemKey.endsWith("_low")
        ) {
          const keyValue = tlogItemKey.split("_")[0];
          if (!acc[keyValue]) {
            acc[keyValue] = {
              avg: [],
              max: [],
              min: [],
              counts: [],
            };
          }

          if (tlogItemKey.endsWith("_high")) {
            if (!isNaN(parseFloat(tlogList[tlogItemKey])))
              acc[keyValue].max.push(parseFloat(tlogList[tlogItemKey]));
          }
          if (tlogItemKey.endsWith("_low")) {
            if (!isNaN(parseFloat(tlogList[tlogItemKey])))
              acc[keyValue].min.push(parseFloat(tlogList[tlogItemKey]));
          }
          if (tlogItemKey.endsWith("_avg")) {
            const value = tlogList[tlogItemKey]?.split("#")?.map(parseFloat);
            if (
              !isNaN(parseFloat(value?.[0])) &&
              !isNaN(parseFloat(value?.[1]))
            ) {
              acc[keyValue].avg.push(parseFloat(value?.[0] * value?.[1]));
              acc[keyValue].counts.push(value?.[1]);
            }
          }
        }
      }

      return acc;
    }, {});

    return Object.keys(highLowMedData).reduce((res, key) => {
      const { avg, max, min, counts } = highLowMedData[key];
      const sum = avg.reduce((acc, val) => acc + val, 0);
      const count_sum = counts.reduce((acc, val) => acc + val, 0);
      res[key] = {
        avg:
          key == "heat" && station_number == "0"
            ? ""
            : count_sum
            ? (sum / count_sum).toFixed(2)
            : "",
        low:
          key == "heat" && station_number == "0"
            ? ""
            : min.length > 0
            ? Math.min(...min).toFixed(2)
            : "",
        high:
          key == "heat" && station_number == "0"
            ? ""
            : max.length > 0
            ? Math.max(...max).toFixed(2)
            : "",
      };
      return res;
    }, {});
  };

  const getStatusForSlog = (logStatus) => {
    const statuses = new Set(logStatus.map((log) => log.status));

    if (statuses.has("false")) {
      return "false";
    }
    if (statuses.has("cross")) {
      return "cross";
    }
    if (statuses.has("ex")) {
      return "ex";
    }
    if (statuses.has("true")) {
      return "true";
    }
    if (statuses.has("-")) {
      return "-";
    }
    return "";
  };

  const getTableInRange = ({
    avgTlogs,
    station_number,
    column,
    currentValue,
    pass_name,
    job_number_function,
    columnNameParams,
  }) => {
    let trailPassNames = avgTlogs?.map((ite) => ite[pass_name]);

    if (trailPassNames?.length != avgTlogs?.length) {
      return {
        doesExist: false,
        showColor: "",
      };
    }
    let allPassNamesFullDetails = [];

    if (job_number_function) {
      allPassNamesFullDetails = tableLimitData?.filter(
        (ite) => ite.job_number == job_number_function
      );

      let enabled = logDataHelperFunctions.isColumnEnabledForRedCircle({
        currentColumn: columnNameParams,
        jobNumber: job_number_function,
        weldParamsShowColumns: weldParamsShowColumns,
      });

      if (!enabled) {
        return {
          doesExist: false,
          showColor: "",
        };
      }
    }

    allPassNamesFullDetails = avgTlogs?.map((tlog) => {
      return station_number == 0
        ? allPassNamesFullDetails?.find(
            (tableLimit) => tableLimit.zone_name == "HEAD"
          )
        : allPassNamesFullDetails?.find(
            (tableLimit) => tableLimit.zone_name == tlog[pass_name]
          );
    });

    let uniquePassNames = [
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...new Set([...allPassNamesFullDetails?.map((ite) => ite?.pass_name)]),
    ];

    if (uniquePassNames?.length > 1 || uniquePassNames?.length == 0) {
      return {
        doesExist: false,
        showColor: "",
      };
    } else {
      return {
        doesExist: true,
        showColor:
          allPassNamesFullDetails?.[0]?.[`${column}_min`] &&
          allPassNamesFullDetails?.[0]?.[`${column}_max`]
            ? Number(currentValue) <
                Number(allPassNamesFullDetails?.[0]?.[`${column}_min`]) ||
              Number(currentValue) >
                Number(allPassNamesFullDetails?.[0]?.[`${column}_max`])
              ? "-color"
              : ""
            : "",
      };
    }
  };

  const getRefinedAggValues = ({
    aggValue,
    lead_pass_names,
    station_number,
    pass_name,
    job_number,
  }) => {
    const getColumnColor = (
      category,
      column,
      columnNameParams,
      job_number_function
    ) => {
      return {
        avg: aggValue?.[category]?.avg
          ? aggValue?.[category]?.avg +
            getTableInRange({
              avgTlogs: lead_pass_names,
              column,
              currentValue: aggValue?.[category]?.avg,
              station_number,
              pass_name,
              job_number_function,
              columnNameParams: columnNameParams,
            })?.showColor
          : "",
        low: aggValue?.[category]?.low
          ? aggValue?.[category]?.low +
            getTableInRange({
              avgTlogs: lead_pass_names,
              column,
              currentValue: aggValue?.[category]?.low,
              station_number,
              pass_name,
              columnNameParams: columnNameParams,

              job_number_function,
            })?.showColor
          : "",
        high: aggValue?.[category]?.high
          ? aggValue?.[category]?.high +
            getTableInRange({
              avgTlogs: lead_pass_names,
              column,
              currentValue: aggValue?.[category]?.high,
              station_number,
              pass_name,
              columnNameParams: columnNameParams,

              job_number_function,
            })?.showColor
          : "",
      };
    };

    return {
      travel: getColumnColor(
        "travel",
        "travel_speed",
        logDataHelperFunctions.allParamsValues.travelSpeed,
        job_number
      ),
      amps: getColumnColor(
        "amps",
        "current",
        logDataHelperFunctions.allParamsValues.arcCurrent,
        job_number
      ),
      volts: getColumnColor(
        "volts",
        "volts",
        logDataHelperFunctions.allParamsValues.arcVolts,
        job_number
      ),
      wire: getColumnColor(
        "wire",
        "wire_speed",
        logDataHelperFunctions.allParamsValues.wireSpeed,
        job_number
      ),
      frequency: {
        avg: aggValue?.frequency?.avg,
        low: aggValue?.frequency?.low,
        high: aggValue?.frequency?.high,
      },
      oscillate: getColumnColor(
        "oscillate",
        "oscillation_width",
        logDataHelperFunctions.allParamsValues.oscillationWidth,
        job_number
      ),
      target: {
        avg: aggValue?.target?.avg,
        low: aggValue?.target?.low,
        high: aggValue?.target?.high,
      },
      horizontal: {
        avg: aggValue?.horizontal?.avg,
        low: aggValue?.horizontal?.low,
        high: aggValue?.horizontal?.high,
      },
      total: {
        avg: aggValue?.total?.avg,
        low: aggValue?.total?.low,
        high: aggValue?.total?.high,
      },
      heat: getColumnColor(
        "heat",
        "heat",
        logDataHelperFunctions.allParamsValues.heat,
        job_number
      ),
      calculated: getColumnColor(
        "calculated",
        "calculated_heat",
        logDataHelperFunctions.allParamsValues.calculatedHeat,
        job_number
      ),
    };
  };

  let index = 1;
  // Generate pass view list
  const passViewListAll = deviceid_events.flatMap((deviceEventId) => {
    const avgTlogFilteredList = avgTlogList.filter(
      (item) => item.s_deviceid_event === deviceEventId
    );

    const slogDeviceEventData = slogData?.find(
      (ite) => ite.deviceid_event == deviceEventId
    );
    const lead_pass_names = avgTlogFilteredList.filter(
      (data) => data.lead_pass_name
    );
    const trail_pass_names = avgTlogFilteredList.filter(
      (data) => data.trail_pass_name
    );

    let lPNL = [];
    if (lead_pass_names?.length > 0) {
      let aggValue = aggregateValues(
        lead_pass_names,
        slogDeviceEventData.station_number
      );

      lPNL.push({
        type: slogDeviceEventData.station_number == "0" ? "" : "Lead",
        status: getStatusForSlog(lead_pass_names),
        stationNumbers: logDataHelperFunctions.getStationName(
          slogDeviceEventData.station_number
        ),
        autoIncrement: slogDeviceEventData?.auto_increment || "-",
        logging_distance: slogDeviceEventData?.logging_distance,
        bug_number: slogDeviceEventData?.bug_number,
        lead_wire_diameter: slogDeviceEventData.lead_wire_diameter,
        pipe_diameter: slogDeviceEventData.pipe_diameter,
        job_number: slogDeviceEventData.job_number,
        trail_wire_diameter: slogDeviceEventData.trail_wire_diameter,
        gps: slogDeviceEventData.gps,
        iwm: slogDeviceEventData.iwm,
        band_diameter: slogDeviceEventData?.band_diameter,
        start_time: slogDeviceEventData?.time,
        end_time: slogDeviceEventData?.c_time,
        station_number: slogDeviceEventData.station_number,
        buyType: slogDeviceEventData.bug_type.split("/")[0].trim(),
        weldStartTime: logDataHelperFunctions.formatWeldStartTime(
          slogDeviceEventData.time,
          true
        ),
        s_deviceid_event: deviceEventId,

        weld_time:
          slogDeviceEventData.weld_time == ""
            ? "-"
            : slogDeviceEventData.weld_time,
        name: logDataHelperFunctions.getPassNamePassZone({
          avgTlogs: lead_pass_names,
          station_number: slogDeviceEventData.station_number,
          pass_name: "lead_pass_name",
          tableLimitData: tableLimitData,
          job_number: slogDeviceEventData.job_number,
          weldNumber: slogDeviceEventData.weld_number,
        }),
        ...getRefinedAggValues({
          aggValue: aggValue,
          lead_pass_names: lead_pass_names,
          station_number: slogDeviceEventData.station_number,
          pass_name: "lead_pass_name",
          job_number: slogDeviceEventData.job_number,
        }),
      });

      index = index + 1;
    }

    let tPNL = [];
    if (trail_pass_names.length) {
      let trailAggValues = aggregateValues(
        trail_pass_names,
        slogDeviceEventData.station_number
      );
      tPNL.push({
        type: slogDeviceEventData.station_number == "0" ? "" : "Trail",
        status: getStatusForSlog(trail_pass_names),
        autoIncrement: slogDeviceEventData?.auto_increment || "-",
        start_time: slogDeviceEventData?.time,
        end_time: slogDeviceEventData?.c_time,
        bug_number: slogDeviceEventData?.bug_number,
        lead_wire_diameter: slogDeviceEventData.lead_wire_diameter,
        pipe_diameter: slogDeviceEventData.pipe_diameter,
        gps: slogDeviceEventData.gps,
        iwm: slogDeviceEventData.iwm,

        job_number: slogDeviceEventData.job_number,
        trail_wire_diameter: slogDeviceEventData.trail_wire_diameter,
        band_diameter: slogDeviceEventData?.band_diameter,
        logging_distance: slogDeviceEventData?.logging_distance,
        stationNumbers: logDataHelperFunctions.getStationName(
          slogDeviceEventData.station_number
        ),
        buyType: slogDeviceEventData.bug_type.split("/")[0].trim(),
        weldStartTime: logDataHelperFunctions.formatWeldStartTime(
          slogDeviceEventData.time,
          true
        ),
        s_deviceid_event: deviceEventId,
        weld_time:
          slogDeviceEventData.weld_time == ""
            ? "-"
            : slogDeviceEventData.weld_time,
        name: logDataHelperFunctions.getPassNamePassZone({
          avgTlogs: trail_pass_names,
          pass_name: "trail_pass_name",
          station_number: slogDeviceEventData.station_number,
          tableLimitData: tableLimitData,
          job_number: slogDeviceEventData.job_number,
          weldNumber: slogDeviceEventData.weld_number,
        }),

        ...getRefinedAggValues({
          aggValue: trailAggValues,
          lead_pass_names: trail_pass_names,
          station_number: slogDeviceEventData.station_number,
          pass_name: "trail_pass_name",
          job_number: slogDeviceEventData.job_number,
        }),
      });

      index = index + 1;
    }

    let passViewListData = [...lPNL, ...tPNL].filter(Boolean);

    return passViewListData;
  });

  const passViewList = passViewListAll
    .filter(
      (item) =>
        item.amps.avg != "" ||
        item.volts.avg != "" ||
        item.wire.avg != "" ||
        item.oscillate.avg != "" ||
        item.target.avg != "" ||
        item.horizontal.avg != "" ||
        item.frequency.avg != "" ||
        item.total.avg != "" ||
        item.calculated.avg != ""
    )
    .sort((a, b) => {
      if (a.type < b.type) return -1;
      if (a.type > b.type) return 1;
      return 0;
    })
    .sort((a, b) => {
      return b.buyType.length - a.buyType.length;
    })
    .sort((a, b) => {
      return (
        Number(a.s_deviceid_event.split("_")[1]) -
        Number(b.s_deviceid_event.split("_")[1])
      );
    })
    .sort((a, b) => {
      if (a.stationNumbers < b.stationNumbers) return -1;
      if (a.stationNumbers > b.stationNumbers) return 1;
      return 0;
    })
    ?.map((ite, i) => {
      return {
        ...ite,
        sl_no: i + 1,
      };
    })
    ?.map((ite) => {
      return passViewHelper.mapperForUnitConversionPassView(ite, reportColumns);
    });

  const handleRowClick = (record) => {
    const {
      stationNumbers,
      weld_time,
      station_number,
      autoIncrement,
      buyType,
      type,
      s_deviceid_event,
      start_time,
      end_time,
      band_diameter,
      logging_distance,
      bug_number,
      lead_wire_diameter,
      trail_wire_diameter,
      pipe_diameter,
      gps,
      iwm,
    } = record;
    history.push("/tlog-data", {
      stationNumbers,
      buyType,
      station_number,
      autoIncrement,
      type,
      weld_number,
      s_deviceid_event,
      weld_time,
      start_time,
      end_time,
      band_diameter,
      logging_distance,
      bug_number,
      lead_wire_diameter,
      trail_wire_diameter,
      pipe_diameter,
      gps,
      iwm,
    });
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const paginationOptions = {
    pageSize,
    onShowSizeChange: handlePageSizeChange,
    showSizeChanger: true,
    pageSizeOptions: commonConstants.ZonePageSizeOptions,
  };

  const getScrollHeight = () => {
    const screenHeight = window.innerHeight;
    if (screenHeight >= 832 && screenHeight <= 956) {
      return 620;
    } else {
      if (screenHeight >= 750 && screenHeight <= 832) {
        return 500;
      }
    }
    return 410;
  };

  return (
    <div className="pass-zone">
      {loader ? (
        <div className="slogdata-loader">
          <CommonLoader />
        </div>
      ) : passViewList.length > 0 ? (
        <Table
          className="zoneview-table"
          dataSource={passViewList}
          scroll={{ x: 5500, y: getScrollHeight() }}
          pagination={paginationOptions}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        >
          {passViewHelper
            .mapPassViewColumns(visibleColumns, reportColumns)
            .map((column) =>
              column.children ? (
                <Table.ColumnGroup key={column.key} title={column.title}>
                  {column.children.map((child) => (
                    <Table.Column
                      key={child.key}
                      title={child.title}
                      dataIndex={child.dataIndex}
                      width={column.width || 100}
                      render={(text) => {
                        if (text.includes("-color")) {
                          return (
                            <span className="red-note">
                              {text.replace("-color", "")}
                            </span>
                          );
                        }
                        return isNaN(text) ? "" : text;
                      }}
                    />
                  ))}
                </Table.ColumnGroup>
              ) : (
                <Table.Column
                  key={column.key}
                  title={column.title}
                  dataIndex={column.dataIndex}
                  width={column.width || 100}
                  render={(text) => {
                    if (column.dataIndex === "status") {
                      return logDataHelperFunctions.getIconForStatus(text);
                    }
                    if (column.dataIndex === "name") {
                      return text == "!" ? (
                        <img src={ExclamationIcon} alt="Exclamation" />
                      ) : (
                        text
                      );
                    }
                    return text;
                  }}
                />
              )
            )}
        </Table>
      ) : (
        <div className="slog-NoData">
          <p>{t("sLogData.noDataAvailable")}</p>
        </div>
      )}
    </div>
  );
};

export default PassZone;
